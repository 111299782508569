import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeScreen from './Screen/HomeScreen';
import PortfolioScreen from './Screen/PortfolioScreen';
import Header from './Components/Header';
import Footer from './Components/Footer';
import GiftShopScreen from './Screen/GiftShopScreen';
import StockNewsFinder from './Screen/StockNewsFinder';
import MathGameScreen from './Screen/MathGameScreen';

const App = () => {
  return (
    <Router>
      <Header />
      <div className="container" style={{ minHeight: '60vh', maxWidth: '800px' }}>
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/portfolio" element={<PortfolioScreen />} />
          <Route path="/portfolio/giftshop" element={<GiftShopScreen />} />
          <Route path="/portfolio/stockFinder" element={<StockNewsFinder />} />
          <Route path="/portfolio/mathgame" element={<MathGameScreen />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
};

export default App;
