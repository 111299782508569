import React from 'react'

const Footer = () => {
  return (
    <footer className='container'>
      <div className="d-flex justify-content-center">
          <div className="py-3">
              Copyright &copy; Kyungwon Lee
          </div>
       </div>
    </footer>
  )
}
export default Footer
