import React from 'react';
import { Link } from 'react-router-dom';
import Meta from '../Components/Meta';

const PortfolioScreen = () => {
  return (
    <>
      <Meta title="Portfolio" />
      <ul id="portfolio" className="list-group list-group-flush mt-4" style={ulStyle}>
        <li className="list-group-item d-flex align-items-center justify-content-between" style={liStyle}>
          <div className="card" style={{ width: '100%' }}>
            <div className="card-body">
              <h3 className="card-title">Gift Shop</h3>
              <h4 className="card-subtitle mb-2 text-muted">eCommerce platform with React, Redux, Express & MongoDB</h4>
              <p>Deployed by render.com</p>
              <a style={btnStyle} href="https://gift-shop-cyqh.onrender.com/" className="card-link" rel="noopener noreferrer" target="_blank">
                Go to Site
              </a>
              <Link style={btnStyle} to="/portfolio/giftshop">
                Detail
              </Link>
            </div>
          </div>
          <div style={{ padding: '1.5rem 0' }}>
            <a href="https://gift-shop-cyqh.onrender.com/" className="card-link" rel="noopener noreferrer" target="_blank">
              <img src="/images/giftshop.jpg" alt="giftshop" style={{ width: '200px', borderRadius: '5px' }} />
            </a>
          </div>
        </li>
        <li className="list-group-item d-flex align-items-center justify-content-between" style={liStyle}>
          <div className="card" style={{ width: '100%' }}>
            <div className="card-body">
              <h3 className="card-title">Stock News Finder App</h3>
              <h4 className="card-subtitle mb-2 text-muted">Simple app to search company's news with React</h4>
              <p>Deployed by netlify.com</p>
              <a style={btnStyle} href="https://stock-news-finder.netlify.app/" className="card-link" rel="noopener noreferrer" target="_blank">
                Go to Site
              </a>
              <Link style={btnStyle} to="/portfolio/stockFinder">
                Detail
              </Link>
            </div>
          </div>
          <div style={{ padding: '1.5rem 0' }}>
            <a href="https://stock-news-finder.netlify.app/" className="card-link" rel="noopener noreferrer" target="_blank">
              <img src="/images/stock.png" alt="stock" style={{ width: '200px', borderRadius: '5px' }} />
            </a>
          </div>
        </li>
        <li className="list-group-item d-flex align-items-center justify-content-between" style={liStyle}>
          <div className="card" style={{ width: '100%' }}>
            <div className="card-body">
              <h3 className="card-title">Math Game Mobile App</h3>
              <h4 className="card-subtitle mb-2 text-muted">Enjoy math game with React-Native, Express, Firebase & Sqlite</h4>
              <p>Explained in a youtube video</p>
              <a style={btnStyle} href="https://youtu.be/hdYkEO9SBLo" className="card-link" rel="noopener noreferrer" target="_blank">
                Go to Site
              </a>
              <Link style={btnStyle} to="/portfolio/mathgame">
                Detail
              </Link>
            </div>
          </div>
          <div style={{ padding: '1.5rem 0' }}>
            <a href="https://youtu.be/hdYkEO9SBLo" className="card-link" rel="noopener noreferrer" target="_blank">
              <img src="/images/mathgame.jpg" alt="mathgame" style={{ height: '120px', width: '100px', borderRadius: '5px' }} />
            </a>
          </div>
        </li>
      </ul>
    </>
  );
};
const ulStyle = {
  listStyle: 'none',
  borderTop: '1px solid #cccccc',
};

const liStyle = {
  borderBottom: '1px solid #cccccc',
  padding: '0 1rem 1.5rem',
};

const btnStyle = {
  display: 'inline-block',
  padding: '0.5rem 1rem',
  marginRight: '1rem',
  marginTop: '0.5rem',
  border: 'none',
  backgroundColor: 'lightblue',
  borderRadius: '5px',
};
export default PortfolioScreen;
