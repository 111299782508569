import React from 'react';
import { Helmet } from 'react-helmet';

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};
Meta.defaultProps = {
  title: "Welcome Kyungwon's Portfolios",
  description: 'If you do nothing nothing will happen',
  keywords: 'React, react native, entry-level developer',
};
export default Meta;
