import React from 'react';
import { Link } from 'react-router-dom';

const Nav = () => {
  return (
    <nav className="d-flex flex-column" style={navStyle}>
      <div style={{ textAlign: 'center', paddingTop: '20px' }}>
        <img src="/images/kw1.JPG" alt="" style={{ borderRadius: '50%', width: '50px', border: 'solid 1px #000000' }} />
      </div>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/portfolio">Portfolio</Link>
        </li>
        <li>
          <a href="https://github.com/jing54321" target="_blank" rel="noopener noreferrer">
            GitHub
          </a>
        </li>
        <li>
          <a href="mailto:leekyungwon98@gmail.com">Send Email</a>
        </li>
      </ul>
    </nav>
  );
};

const navStyle = {
  backgroundColor: '#30336b',
  width: '200px',
  minHeight: '100vh',
  height: '100%',
  borderRight: '2px solid rgba(200, 200, 200, 0.1)',
  position: 'absolute' /* fixed is ok  */,
  transform: 'translateX(-100%)',
};

export default Nav;
