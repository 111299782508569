import React from 'react';
import { Link } from 'react-router-dom';
import Meta from '../Components/Meta';

const StockNewsFinder = () => {
  return (
    <>
      <Meta title="Stock News Finder" />
      <div>
        <h2>Stock News Finder</h2>
        <Link style={btnStyle} to="/portfolio">
          Back
        </Link>
        <a style={btnStyle} href="https://stock-news-finder.netlify.app/" className="card-link" rel="noopener noreferrer" target="_blank">
          Go to Site
        </a>
        <p style={pStyle}>This App made by React is for finding profile, news and earnings information of stock that you want.</p>

        <h2>Functionality</h2>
        <ul style={{ paddingLeft: '1rem', marginBottom: '1rem' }}>
          <li style={liStyle}>Search stocks using ticker</li>
          <li style={liStyle}>Basic stock information</li>
          <li style={liStyle}>List stock news</li>
          <li style={liStyle}>Find stock earnings</li>
        </ul>
        <h2>Libraries</h2>
        <ul style={{ paddingLeft: '1rem', marginBottom: '1rem' }}>
          <li style={liStyle}>React, React-router-dom</li>
          <li style={liStyle}>State management using react hooks (useContext, useReducer)</li>
          <li style={liStyle}>
            API info : Finnhub{' '}
            <a href="https://finnhub.io/docs/api/quote" className="card-link" rel="noopener noreferrer" target="_blank">
              (https://finnhub.io/docs/api/quote)
            </a>
          </li>
        </ul>
        <div style={{ textAlign: 'center' }}>
          <img src="https://user-images.githubusercontent.com/75094086/210109209-fbc994a1-20d1-4de7-8f06-d4ef11558e4a.gif" alt="giftshop" style={{ width: '800px', borderRadius: '3px' }} />
        </div>
      </div>
    </>
  );
};
const pStyle = {
  fontSize: '1.1rem',
  lineHeight: '2rem',
};
const liStyle = {
  padding: '0.4rem 0',
};
const btnStyle = {
  padding: '0.5rem 1rem',
  marginRight: '1rem',
  marginTop: '0.5rem',
  border: 'none',
  backgroundColor: 'lightgrey',
  borderRadius: '5px',
};
export default StockNewsFinder;
