import React from 'react';
import { Link } from 'react-router-dom';
import Meta from '../Components/Meta';

const MathGameScreen = () => {
  return (
    <>
      <Meta title="Math Game" />
      <div>
        <h2>Math Game</h2>
        <Link style={btnStyle} to="/portfolio">
          Back
        </Link>
        <a style={btnStyle} href="https://youtu.be/hdYkEO9SBLo" className="card-link" rel="noopener noreferrer" target="_blank">
          Go to Site
        </a>
        <p style={pStyle}>This app is a simple math game app that matches the sum of two random numbers.</p>

        <h2>Functionality</h2>
        <ul style={{ paddingLeft: '1rem', marginBottom: '1rem' }}>
          <li style={liStyle}>Log in</li>
          <li style={liStyle}>Create new account</li>
          <li style={liStyle}>Generate random numbers</li>
          <li style={liStyle}>Input answer and show result</li>
          <li style={liStyle}>Manage recent leader board</li>
        </ul>
        <h2>Libraries</h2>
        <ul style={{ paddingLeft: '1rem', marginBottom: '1rem' }}>
          <li style={liStyle}>React</li>
          <li style={liStyle}>React-native</li>
          <li style={liStyle}>React-native-material</li>
          <li style={liStyle}>React-navigation</li>
          <li style={liStyle}>Express</li>
          <li style={liStyle}>Sqlite and firebase DB</li>
        </ul>
        <div style={{ textAlign: 'center', backgroundColor: '#111111', padding: '1rem' }}>
          <img src="https://user-images.githubusercontent.com/75094086/229655667-4733e29f-be7c-40d3-8653-c5cde7e9c5b0.gif" alt="giftshop" style={{ width: '250px', borderRadius: '3px' }} />
        </div>
      </div>
    </>
  );
};
const pStyle = {
  fontSize: '1.1rem',
  lineHeight: '2rem',
};
const liStyle = {
  padding: '0.4rem 0',
};
const btnStyle = {
  padding: '0.5rem 1rem',
  marginRight: '1rem',
  marginTop: '0.5rem',
  border: 'none',
  backgroundColor: 'lightgrey',
  borderRadius: '5px',
};
export default MathGameScreen;
