import React, { useState } from 'react';
import Nav from './Nav';
import { Link } from 'react-router-dom';

const Header = () => {
  const [nav, setNav] = useState(false);

  const showNav = () => {
    if (!nav) {
      document.querySelector('body').classList.add('show-nav');
      setNav(true);
    } else {
      document.querySelector('body').classList.remove('show-nav');
      setNav(false);
    }
  };

  const mouseEnter = e => {
    e.target.style.backgroundColor = '#6bc0f7';
    e.target.style.transform = 'scale(1.1)';
  };

  const mouseLeave = e => {
    e.target.style.backgroundColor = '#374d9a';
    e.target.style.transform = 'scale(1.0)';
  };

  return (
    <>
      <Nav />
      <button id="toggle" style={toggleStyle} onClick={showNav}>
        <i className="fas fa-bars"></i>
      </button>

      <div className="container-fluid py-2" style={headerStyle}>
        <div className="d-flex flex-column align-items-center">
          <h1>Welcome to the Kyungwon Lee's Portfolio</h1>
          <div>
            <Link to="/">
              <img src="/images/kw1.JPG" alt="" style={{ borderRadius: '50%', width: '100px', border: 'solid 1px #000000' }} />
            </Link>
          </div>
          <h3>
            <em>"If you do nothing, nothing will happen."</em>
          </h3>
          <p>Thank you very much for visiting my website. </p>
          <a
            style={buttonStyle}
            onMouseOver={mouseEnter}
            onMouseLeave={mouseLeave}
            className="btn bg-primary"
            href="mailto:leekyungwon98@gmail.com"
            role="button"
            rel="noopener noreferrer"
            target="_blank"
          >
            Contact Me
          </a>
        </div>
        <div style={divider}>
          <svg style={svg} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" style={fill}></path>
          </svg>
        </div>
      </div>
    </>
  );
};

const headerStyle = {
  backgroundColor: '#30336b',
  background: 'linear-gradient(90deg, rgba(131,58,180,1) 25%, rgba(223,175,191,1) 100%)',
  color: '#ffffff',
  height: '33rem',
};

const divider = {
  position: 'absolute',
  top: '390px',
  left: 0,
  width: '100%',
  overflow: 'hidden',
  lineHeight: 0,
  transform: 'rotate(180deg)',
};

const fill = {
  fill: '#FFFFFF',
};

const svg = {
  position: 'relative',
  display: 'block',
  width: 'calc(100% + 1.3px)',
  height: '150px',
  transform: 'rotateY(180deg)',
};
const toggleStyle = {
  position: 'absolute',
  top: '20px',
  left: '20px',
  padding: '8px 12px',
  border: 'none',
  color: '#ffffff',
  backgroundColor: '#22244b',
  fontSize: '1.5rem',
  borderRadius: '5px',
  cursor: 'pointer',
};

const buttonStyle = {
  padding: '16px',
  color: '#ffffff',
  backgroundColor: '#374d9a',
  borderRadius: '5px',
  boxShadow: '0 0 5px',
  marginTop: '10px',
  transition: 'all 0.2s ease',
};

export default Header;
