import React from 'react';
import Meta from '../Components/Meta';

const HomeScreen = () => {
  return (
    <>
      <Meta title="Welcome Kyungwon's Portfolios" />
      <div>
        <h2>What is this landing page about?</h2>
        <p>This website was designed for the purpose of introducing myself as a developer and explaining the websites I have created. Take your time and look around.</p>

        <h2>Tell me more</h2>
        <p>
          I majored in Computer Systems Technician - Software Support at Mohawk College in Hamilton, Ontario and will be graduating this June. I immigrated to Canada 3 years ago and wanted to
          challenge myself in a new field, so I studied IT, which I had always been interested in.
        </p>
        <p>
          I majored in architecture in Korea and worked for 11years at the construction company. After participating in the company's new ERP system construction as an architectural engineer, I became
          interested in software developers.
        </p>
        <p>
          I learned several languages at college, but I was particularly interested in web development. So, I made an e-commerce site using React and Express as part of my portfolio. In the future, I
          want to become a developer who helps enrich people's daily lives.
        </p>
        <h2>I've experienced</h2>
        <ul style={{ paddingLeft: '1rem' }}>
          <li style={liStyle}>Web : HTML, CSS, JavaScript, ASP.NET, PHP, React and React Native, express</li>
          <li style={liStyle}>Programming Language : JAVA, C#, Python</li>
          <li style={liStyle}>DataBase : MySql, MongoDB</li>
        </ul>
      </div>
    </>
  );
};

const liStyle = {
  padding: '0.4rem 0',
  fontSize: '1.1rem',
};
export default HomeScreen;
