import React from 'react';
import { Link } from 'react-router-dom';
import Meta from '../Components/Meta';

const GiftShopScreen = () => {
  return (
    <>
      <Meta title="Gift Shop" />
      <div>
        <h2>Gift Shop</h2>
        <Link style={btnStyle} to="/portfolio">
          Back
        </Link>
        <a style={btnStyle} href="https://gift-shop-cyqh.onrender.com/" className="card-link" rel="noopener noreferrer" target="_blank">
          Go to Site
        </a>
        <p style={pStyle}>This website was built like a real world eCommerce platform using react, express, mongoDB and redux. It was deployed by Render in conjunction with Github.</p>

        <h2>Functionality</h2>
        <ul style={{ paddingLeft: '1rem', marginBottom: '1rem' }}>
          <li style={liStyle}>Shopping cart</li>
          <li style={liStyle}>Top products carousel </li>
          <li style={liStyle}>Product search and pagination</li>
          <li style={liStyle}>Product review and ratings</li>
          <li style={liStyle}>Admin product, order, user management</li>
          <li style={liStyle}>Checkout process (shipping, payment etc) </li>
          <li style={liStyle}>paypal / credit card integration</li>
        </ul>
        <h2>Libraries</h2>
        <ul style={{ paddingLeft: '1rem', marginBottom: '1rem' }}>
          <li style={liStyle}>React, React router and hooks</li>
          <li style={liStyle}>React-Bootstrap UI library</li>
          <li style={liStyle}>Redux for managing global state</li>
          <li style={liStyle}>Express</li>
          <li style={liStyle}>Environment variables</li>
          <li style={liStyle}>JSON Web Token</li>
          <li style={liStyle}>paypal API</li>
          <li style={liStyle}>MongoDB and mongoose</li>
          <li style={liStyle}>Amazon S3 and multer</li>
        </ul>
        <div style={{ textAlign: 'center' }}>
          <img src="/images/giftarc.png" alt="giftshop" style={{ width: '800px', borderRadius: '3px' }} />
        </div>
      </div>
    </>
  );
};
const pStyle = {
  fontSize: '1.1rem',
  lineHeight: '2rem',
};
const liStyle = {
  padding: '0.4rem 0',
};
const btnStyle = {
  padding: '0.5rem 1rem',
  marginRight: '1rem',
  marginTop: '0.5rem',
  border: 'none',
  backgroundColor: 'lightgrey',
  borderRadius: '5px',
};
export default GiftShopScreen;
